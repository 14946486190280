import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import { Field, withFormik } from 'formik'
import UnderstandTheDifference from '../components/UnderstandTheDifference'

import Layout from '../components/Layout'
import compose from 'lodash/fp/flowRight'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import MuiSelect from '../components/Dropdown'
import H3 from '../components/H3'
import H5 from '../components/H5'
import Subtitle2 from '../components/SubTitle2'
import Paragraph from '../components/Paragraph'
import Body1 from '../components/Body1'
import Body2 from '../components/Body2'
import Button from '../components/Button/Button'
import ConfigureCloud from '../components/ConfigureRangeSlider'
import { withSolutions } from '../components/SolutionProvider'
import RecommendedPlans from '../components/RecommendedPlans'
import PricePerFeature from '../components/PricePerFeature'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledPrice,
  header,
  currency,
  styledRecommendedPlans,
  styledCloudConfig
} from '../components/styles/DedicatedServersPrice.styles'
import { btnLarge } from '../components/styles/OutlineButton.styles'
import Radio from '../components/Radio'
import Subtitle from '../components/SubTitle'
import Separator from './../components/Separator'

const initialModals = {
  support: false,
  moreInfo: false
}

const panelsDescs = [
  {
    title: 'Sem Painel de Controle:',
    description: 'Sem painel de controle.'
  },
  {
    title: 'Painel cPanel:',
    description: 'Famoso no mercado.'
  },
  {
    title: 'Painel de Plesk:',
    description: 'Simplifica o gerenciamento da infraestrutura cloud e torna a criação e execução de websites e aplicações mais fácil para iniciantes e experientes. Use o Plesk para administrar seu Cloud Server.'
  }
]

const SelectedDesc = ({
  title,
  description
}) => {
  return (
    <Div>
      <Subtitle
        color='black'
        variant='mediumEmphasis'
        mb={[2]}
      >
        {title}
      </Subtitle>
      <Body2
        color='black'
        variant='mediumEmphasis'
      >
        {description}
      </Body2>
    </Div>
  )
}

const dcPrices = {
  usa: {
    vcpu: 35.40,
    ssd: 0.60,
    initialBandwidth: 0,
    bandwidth: 40.96,
    initialBackup: 0,
    backup: 0.20,
    ram: 8.00,
    ip: 20.00,
    controlPanel: {
      none: 0,
      plesk: 60,
      cpanel: 74.99,
      cpanelPremier: 249
    },
    os: {
      centos: 0,
      cloudlinux: 50,
      debian: 0,
      ubuntu: 0,
      win2012: 108,
      win2016: 108
    }
  },
  ne: {
    vcpu: 46.02,
    ssd: 0.78,
    initialBandwidth: 0,
    bandwidth: 40.96,
    initialBackup: 0,
    backup: 0.28,
    ram: 10.40,
    ip: 26,
    controlPanel: {
      none: 0,
      plesk: 60,
      cpanel: 74.99,
      cpanelPremier: 249
    },
    os: {
      centos: 0,
      cloudlinux: 50,
      debian: 0,
      ubuntu: 0,
      win2012: 108,
      win2016: 108
    }
  },
  col: {
    vcpu: 46.02,
    ssd: 0.78,
    initialBandwidth: 0,
    bandwidth: 40.96,
    initialBackup: 0,
    backup: 0.28,
    ram: 10.40,
    ip: 26,
    controlPanel: {
      none: 0,
      plesk: 60,
      cpanel: 74.99,
      cpanelPremier: 249
    },
    os: {
      centos: 0,
      cloudlinux: 50,
      debian: 0,
      ubuntu: 0,
      win2012: 108,
      win2016: 108
    }
  },
  mex: {
    vcpu: 46.02,
    ssd: 0.78,
    initialBandwidth: 0,
    bandwidth: 40.96,
    initialBackup: 0,
    backup: 0.28,
    ram: 10.40,
    ip: 26,
    controlPanel: {
      none: 0,
      plesk: 60,
      cpanel: 74.99,
      cpanelPremier: 249
    },
    os: {
      centos: 0,
      cloudlinux: 50,
      debian: 0,
      ubuntu: 0,
      win2012: 108,
      win2016: 108
    }
  }
}

const calculateTotal = ({ prices, cloudServer, bandwidth, controlPanel, os }) => {
  const currentPrices = dcPrices[cloudServer]
  let total = Object.keys(prices).map((key) => currentPrices[key] * prices[key]).reduce((acc, curr) => acc + curr, 0)
  if (bandwidth !== 10) {
    total = total + currentPrices.bandwidth * bandwidth
  }
  total = total + currentPrices.controlPanel[controlPanel]
  total = total + currentPrices.os[os]

  return total
}

const PriceForm = ({
  values,
  setModalVisibility
}) => {
  const total = calculateTotal(values)
  return (
    <>
      <Row
        flexDirection={['column']}
        pt={50}
        id='customize-sua-cloud'
      >
        <Column
          width={[1]}
          mb={30}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <H5
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                marginBottom: 24,
                textAlign: 'center'
              }}
            >
              Customize sua Cloud
            </H5>
            <Field
              name='cloudServer'
              render={({ field, form }) => {
                const options = [
                  { label: 'DC Orlando - USA', value: 'usa' },
                  { label: 'DC Nordeste - Brasil', value: 'ne' },
                  { label: 'DC Colombia - COL', value: 'col' },
                  { label: 'DC México - MEX', value: 'mex' }                  
                ]

                return (
                  <MuiSelect
                    options={options}
                    label='DC Nordeste - Brasil'
                    value={options.find(option => option.value === field.value)}
                    onChange={(value) => {
                      form.setFieldValue(field.name, value.value)
                    }}
                    isClearable={false}
                    style={{
                      maxWidth: 328,
                      marginBottom: 20
                    }}
                  />
                )
              }}
            />
            <Subtitle
              maxWidth={[200, '100%']}
              style={{
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'center'
              }}
            >
              Brasil ou Estados Unidos? 
              <span
                onClick={() => setModalVisibility('moreInfo')(true)}
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  marginLeft: 2,
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
              >
                Entenda a diferença
              </span>
            </Subtitle>
          </div>
        </Column>            
      </Row>
      <Div
        alignItems={['center']}
        maxWidth={[328, 328, 328, 1032]}
        m={['0 auto 24px']}
      >
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={['stretch']}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1]}
            display={['flex']}
            style={{
              border: '1px solid rgb(243, 245, 245)',
              borderRadius: 12
            }}
            mb={[24, 24, 24, 0]}
          >
            <Div
              alignItems={['center']}
              p={[24]}
            >
              <H5
                color='black'
                variant='highEmphasis'
                mb={[4]}
              >
                Sistema Operacional
              </H5>
              <Separator
                style={{
                  marginBottom: 24
                }}
              />
              <Field
                name='os'
                render={({ field, form }) => {
                  const options = {
                    none: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'Debian', value: 'debian' },
                      { label: 'Ubuntu', value: 'ubuntu' },
                      { label: 'Windows Server 2012', value: 'win2012' },
                      { label: 'Windows Server 2016', value: 'win2016' }
                    ],
                    plesk: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'Windows Server 2012', value: 'win2012' },
                      { label: 'Windows Server 2016', value: 'win2016' }
                    ],
                    cpanel: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'CloudLinux 7', value: 'cloudlinux' }
                    ],
                    cpanelPremier: [
                      { label: 'CentOS 7', value: 'centos' },
                      { label: 'CloudLinux 7', value: 'cloudlinux' }
                    ]
                  }

                  return (
                    <MuiSelect
                      opaque
                      options={options[values.controlPanel]}
                      label='Sistema Operacional'
                      value={options[values.controlPanel].find(option => option.value === field.value)}
                      onChange={(value) => {
                        form.setFieldValue(field.name, value.value)
                      }}
                      isClearable={false}
                      style={{
                        maxWidth: 328,
                        marginBottom: 10
                      }}
                    />
                  )
                }}
              />
            </Div>
          </Column>
        </Row>
      </Div>
      <Row
        justifyContent={'center'}
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={'center'}
      >
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.vcpu'
              component={ConfigureCloud}
              title='vCPU'
              max={16}
              normalizeValue={(value) => value.toString().padStart(2, '0')}
              price={dcPrices[values.cloudServer]['vcpu']}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.ram'
              component={ConfigureCloud}
              title='RAM'
              unit='GB'
              max={64}
              price={dcPrices[values.cloudServer]['ram']}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.ssd'
              component={ConfigureCloud}
              title='SSD Primary Storage'
              unit='GB'
              min={6}
              max={600}
              price={dcPrices[values.cloudServer]['ssd']}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
      </Row>
      <Row
        justifyContent={'center'}
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems={'center'}
      >
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='bandwidth'
              component={ConfigureCloud}
              title='Bandwidth'
              unit='TB'
              min={10}
              max={100}
              price={values.bandwidth === 10
                ? dcPrices[values.cloudServer]['initialBandwidth']
                : dcPrices[values.cloudServer]['bandwidth']
              }
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.backup'
              component={ConfigureCloud}
              title='Backup Snapshot'
              unit='GB'
              min={0}
              max={1000}
              price={values.backup === 10
                ? dcPrices[values.cloudServer]['initialBackup']
                : dcPrices[values.cloudServer]['backup']
              }
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
        <Column
          mb={[30]}
        >
          <Div
            maxWidth={['100%', 328]}
            width={['100%']}
          >
            <Field
              name='prices.ip'
              component={ConfigureCloud}
              title='IPv4'
              price={dcPrices[values.cloudServer]['ip']}
              max={100}
              style={{
                border: '1px solid #f3f5f5'
              }}
            />
          </Div>
        </Column>
      </Row>
      <Div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 12
        }}
        alignItems={['center']}
        maxWidth={[328, 328, 328, 1032]}
        m={['0 auto 24px']}
        p={[24, 24, 24, '24px 0']}
      >
        <H5
          color='black'
          variant='highEmphasis'
          mb={[4]}
        >
          Painel de Controle
        </H5>
        <Separator
          style={{
            marginBottom: 24
          }}
        />
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={'center'}
          mb={[24]}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center', 'center', 'center', 'flex-end']}
            mb={[21, 21, 21, 0]}
          >
            <Div
              alignItems={['center']}
              maxWidth={['100%', '100%', '100%', 240]}
            >
              <Div>
                <form>
                  <Field
                    name='controlPanel'
                    render={({ field, form }) => {
                      return (
                        <>
                          <Radio
                            label='Sem Painel de Controle'
                            subLabel='Sem Painel de Controle'
                            value='none'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                          <Radio
                            label='cPanel - Solo (1 conta)'
                            subLabel='Total controle do seu Cloud Server Linux'
                            value='cpanel'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                          <Radio
                            label='cPanel - Premier (Contas Ilimitadas)'
                            subLabel='Total controle do seu Cloud Server Linux'
                            value='cpanelPremier'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                          <Radio
                            label='Plesk'
                            subLabel='Total controle do seu Cloud Server Linux ou Windows'
                            value='plesk'
                            style={{
                              marginBottom: 15
                            }}
                            field={field}
                            form={form}
                            rawValue
                            onChange={() => {
                              form.setFieldValue('os', 'centos')
                            }}
                          />
                        </>
                      )
                    }}
                  />
                </form>
              </Div>
            </Div>
          </Column>
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center']}
          >
            <Div
              alignItems={['center']}
              maxWidth={[305]}
            >
              <SelectedDesc
                {...panelsDescs[values.controlPanel]}
              />
            </Div>
          </Column>
        </Row>
      </Div>
      <Div
        style={{
          border: '1px solid #E0E0E0',
          borderRadius: 12
        }}
        alignItems={['center']}
        maxWidth={[328, 328, 328, 1032]}
        m={['0 auto 24px']}
        p={[24, 24, 24, '24px 0']}
      >
        <Row
          justifyContent={'center'}
          flexDirection={['column', 'column', 'column', 'row']}
          alignItems={'center'}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center', 'center', 'center', 'flex-end']}
            mb={[21, 21, 21, 0]}
          >
            <Div
              alignItems={['center']}
              maxWidth={['100%', '100%', '100%', 240]}
            >
              <H5
                color='black'
                variant='highEmphasis'
                mb={[4]}
              >
                Custo Mensal
              </H5>
              <Separator
                style={{
                  marginBottom: 24
                }}
              />
              <Div
                alignItems={['center']}
              >
                <H3
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                  style={{
                    letterSpacing: 0.25,
                    marginBottom: 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 296,
                    fontWeight: 500
                  }}
                >
                  <span css={currency}>R$</span>
                  {Math.trunc(total)}
                  <span css={currency}>,{/\.([0-9]+)/.exec(total.toFixed(2))[1]}</span>
                </H3>
                <Separator
                  style={{
                    marginBottom: 24
                  }}
                />
                {false && <Div
                  flexDirection={['row']}
                  justifyContent={['center']}
                >
                  <Paragraph 
                    style={{
                      fontSize: 15.8,
                      textAlign: 'left',
                      fontWeight: 500,
                      color: 'rgba(0, 0, 0, 0.87)',
                    }}>
                    Custo por Hora
                  </Paragraph>
                  <Body1 
                    style={{
                      fontSize: 15.8,
                      fontWeight: 600,
                      color: 'rgba(0, 0, 0, 0.87)',
                      marginLeft: 4
                    }}>
                    R$ 0,45380
                  </Body1>
                </Div>}
              </Div>
            </Div>
          </Column>
          <Column
            width={[1, 1, 1, 5/12]}
            display={['flex']}
            justifyContent={['center']}
          >
            <Div
              alignItems={['center']}
              maxWidth={[305]}
            >
              <Button
                onClick={() => navigate('/contatos')}
                schema='secondary'
                color='white'
                variant='highEmphasis'
                width={['100%']}
                maxWidth={['240px']}
                style={{
                  display: 'flex',
                  border: 'none',
                  margin: '0 auto',
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)'
                }}
              >
                CONSULTE
              </Button>
            </Div>
          </Column>
        </Row>
      </Div>
    </>
  )
}

const EnhancePriceForm = withFormik({
  mapPropsToValues: (props) => ({
    managed: 0,
    cloudServer: 'usa',
    controlPanel: 'none',
    os: 'centos',
    bandwidth: 10,
    prices: {
      vcpu: 1,
      ram: 1,
      ssd: 6,
      backup: 0,
      ip: 1
    }
  })
})(PriceForm)

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  const [loadMore, setLoadMore] = useState(false)
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }

  const [selector, setSelector]= useState(0)

  return (
    <Fragment>
      <section css={styledPrice}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
            mb={30}
          >
            <Column
              width={[1]}
            >
              <H3>Cloud Server HostDime</H3>
              <Paragraph>Crie e implemente máquinas virtuais que atendam às suas necessidades!</Paragraph>
            </Column>
          </Row>
          <Row
            flexDirection={['column']}
            textAlign={['center']}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <H5
                  style={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    marginBottom: 24,
                    textAlign: 'center'
                  }}
                >
                  Preços por cada tipo de recurso
                </H5>
                <MuiSelect
                  options={[
                    {label: 'DC Nordeste - Brasil', value: 0},
                    {label: 'DC Orlando - USA', value: 1},
                    {label: 'DC Colombia - COL', value: 2},
                    {label: 'DC México - MEX', value: 3}
                  ]}
                  label='DC Nordeste - Brasil'
                  onChange={(e) =>  setSelector(e.value)}
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20,
                    textAlign: 'left'
                  }}
                />
                <Subtitle2
                  maxWidth={[200, '100%']}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    textAlign: 'center'
                  }}
                >
                  Brasil ou Estados Unidos? 
                  <span
                    onClick={() => setModalVisibility('moreInfo')(true)}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      marginLeft: 2,
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                  >
                    Entenda a diferença
                  </span>
                </Subtitle2>
              </div>
            </Column>
          </Row>
          <Fragment>
            {selector === 0 ?
              <Div>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <PricePerFeature
                        productName='RAM'
                        unity='Unidade'
                        unityPrice='1G'
                        hour='Valor por hora'
                        priceHour='R$ 0,01424657'
                        month='Valor por mês'
                        priceMonth='R$ 10,40'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <PricePerFeature
                        productName='vCPU'
                        unity='Unidade'
                        unityPrice='1 vCPU'
                        hour='Valor por hora'
                        priceHour='R$ 0,06304110'
                        month='Valor por mês'
                        priceMonth='R$ 46,02'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <PricePerFeature
                        productName='SSD'
                        unity='Unidade'
                        unityPrice='1G'
                        hour='Valor por hora'
                        priceHour='R$ 0,00106850'
                        month='Valor por mês'
                        priceMonth='R$ 0,78'
                      />
                    </Div>
                  </Column>
                </Row>
                {loadMore && (
                  <>
                    <Row
                      justifyContent={'center'}
                      flexDirection={['column', 'column', 'column', 'row']}
                      alignItems={'center'}
                    >
                      <Column
                        mb={[30]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <PricePerFeature
                            productName='Banda Inbound'
                            unity='Unidade'
                            unityPrice='1G'
                            hour='Valor por hora'
                            priceHour='Free'
                            month='Valor por mês'
                            priceMonth='Free'
                          />
                        </Div>
                      </Column>
                      <Column
                        mb={[30]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <PricePerFeature
                            productName='Banda Outbound'
                            unity='Unidade'
                            unityPrice='1G'
                            hour='Valor por hora'
                            priceHour={`10TB Free + R$ 0,04\n por GB excedente`}
                            month='Valor por mês'
                            priceMonth='- - -'
                          />
                        </Div>
                      </Column>
                      <Column
                        mb={[30]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <PricePerFeature
                            productName='IPv4'
                            unity='Unidade'
                            unityPrice='1'
                            hour='Valor por hora'
                            priceHour='R$ 0,03561644'
                            month='Valor por mês'
                            priceMonth='R$ 26,00'
                          />
                        </Div>
                      </Column>
                    </Row>
                    <Row
                      justifyContent={'center'}
                      flexDirection={['column', 'column', 'column', 'row']}
                      alignItems={'center'}
                    >
                      <Column
                        mb={[30]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <PricePerFeature
                            productName='IPv6'
                            unity='Unidade'
                            unityPrice='1'
                            hour='Valor por hora'
                            priceHour='Free'
                            month='Valor por mês'
                            priceMonth='Free'
                          />
                        </Div>
                      </Column>
                      <Column
                        mb={[30]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <PricePerFeature
                            productName='Backup / Image Storage (SATA)'
                            unity='Unidade'
                            unityPrice='1GB'
                            hour='Valor por hora'
                            priceHour='R$ 0,00035616'
                            month='Valor por mês'
                            priceMonth='R$ 0,26'
                          />
                        </Div>
                      </Column>
                      <Column
                        mb={[30]}
                      >
                        <Div
                          maxWidth={['100%', 328]}
                        >
                          <PricePerFeature
                            productName='Auto Scaling'
                            unity='Unidade'
                            unityPrice='On/Off'
                            hour='Valor por hora'
                            priceHour='Free'
                            month='Valor por mês'
                            priceMonth='Free'
                          />
                        </Div>
                      </Column>
                    </Row>
                  </>
                )}
              </Div>
              : selector === 1 ?
                <Div>
                  <Row
                    justifyContent={'center'}
                    flexDirection={['column', 'column', 'column', 'row']}
                    alignItems={'center'}
                  >
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='RAM'
                          unity='Unidade'
                          unityPrice='1G'
                          hour='Valor por hora'
                          priceHour='R$ 0,0109589'
                          month='Valor por mês'
                          priceMonth='R$ 8,00'
                        />
                      </Div>
                    </Column>
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='vCPU'
                          unity='Unidade'
                          unityPrice='1 vCPU'
                          hour='Valor por hora'
                          priceHour='R$ 0,0484931'
                          month='Valor por mês'
                          priceMonth='R$ 35,40'
                        />
                      </Div>
                    </Column>
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='SSD'
                          unity='Unidade'
                          unityPrice='1G'
                          hour='Valor por hora'
                          priceHour='R$ 0,000821918'
                          month='Valor por mês'
                          priceMonth='R$ 0,60'
                        />
                      </Div>
                    </Column>
                  </Row>
                  {loadMore && (
                    <>
                      <Row
                        justifyContent={'center'}
                        flexDirection={['column', 'column', 'column', 'row']}
                        alignItems={'center'}
                      >
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Banda Inbound'
                              unity='Unidade'
                              unityPrice='1G'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Banda Outbound'
                              unity='Unidade'
                              unityPrice='1G'
                              hour='Valor por hora'
                              priceHour={`10TB Free + R$ 0,04\n por GB excedente`}
                              month='Valor por mês'
                              priceMonth='- - -'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='IPv4'
                              unity='Unidade'
                              unityPrice='1'
                              hour='Valor por hora'
                              priceHour='R$ 0,02739726'
                              month='Valor por mês'
                              priceMonth='R$ 20,00'
                            />
                          </Div>
                        </Column>
                      </Row>
                      <Row
                        justifyContent={'center'}
                        flexDirection={['column', 'column', 'column', 'row']}
                        alignItems={'center'}
                      >
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='IPv6'
                              unity='Unidade'
                              unityPrice='1'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Backup / Image Storage (SATA)'
                              unity='Unidade'
                              unityPrice='1GB'
                              hour='Valor por hora'
                              priceHour='R$ 0,00027397'
                              month='Valor por mês'
                              priceMonth='R$ 0,20'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Auto Scaling'
                              unity='Unidade'
                              unityPrice='On/Off'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                      </Row>
                    </>
                  )}
                </Div>
              : selector === 2 ?
                <Div>
                  <Row
                    justifyContent={'center'}
                    flexDirection={['column', 'column', 'column', 'row']}
                    alignItems={'center'}
                  >
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='RAM'
                          unity='Unidade'
                          unityPrice='1G'
                          hour='Valor por hora'
                          priceHour='R$ 0,01424657'
                          month='Valor por mês'
                          priceMonth='R$ 10,40'
                        />
                      </Div>
                    </Column>
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='vCPU'
                          unity='Unidade'
                          unityPrice='1 vCPU'
                          hour='Valor por hora'
                          priceHour='R$ 0,06304110'
                          month='Valor por mês'
                          priceMonth='R$ 46,02'
                        />
                      </Div>
                    </Column>
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='SSD'
                          unity='Unidade'
                          unityPrice='1G'
                          hour='Valor por hora'
                          priceHour='R$ 0,00106850'
                          month='Valor por mês'
                          priceMonth='R$ 0,78'
                        />
                      </Div>
                    </Column>
                  </Row>
                  {loadMore && (
                    <>
                      <Row
                        justifyContent={'center'}
                        flexDirection={['column', 'column', 'column', 'row']}
                        alignItems={'center'}
                      >
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Banda Inbound'
                              unity='Unidade'
                              unityPrice='1G'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Banda Outbound'
                              unity='Unidade'
                              unityPrice='1G'
                              hour='Valor por hora'
                              priceHour={`10TB Free + R$ 0,04\n por GB excedente`}
                              month='Valor por mês'
                              priceMonth='- - -'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='IPv4'
                              unity='Unidade'
                              unityPrice='1'
                              hour='Valor por hora'
                              priceHour='R$ 0,03561644'
                              month='Valor por mês'
                              priceMonth='R$ 26,00'
                            />
                          </Div>
                        </Column>
                      </Row>
                      <Row
                        justifyContent={'center'}
                        flexDirection={['column', 'column', 'column', 'row']}
                        alignItems={'center'}
                      >
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='IPv6'
                              unity='Unidade'
                              unityPrice='1'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Backup / Image Storage (SATA)'
                              unity='Unidade'
                              unityPrice='1GB'
                              hour='Valor por hora'
                              priceHour='R$ 0,00035616'
                              month='Valor por mês'
                              priceMonth='R$ 0,26'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Auto Scaling'
                              unity='Unidade'
                              unityPrice='On/Off'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                      </Row>
                    </>
                  )}
                </Div>
              :
                <Div>
                  <Row
                    justifyContent={'center'}
                    flexDirection={['column', 'column', 'column', 'row']}
                    alignItems={'center'}
                  >
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='RAM'
                          unity='Unidade'
                          unityPrice='1G'
                          hour='Valor por hora'
                          priceHour='R$ 0,01424657'
                          month='Valor por mês'
                          priceMonth='R$ 10,40'
                        />
                      </Div>
                    </Column>
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='vCPU'
                          unity='Unidade'
                          unityPrice='1 vCPU'
                          hour='Valor por hora'
                          priceHour='R$ 0,06304110'
                          month='Valor por mês'
                          priceMonth='R$ 46,02'
                        />
                      </Div>
                    </Column>
                    <Column
                      mb={[30]}
                    >
                      <Div
                        maxWidth={['100%', 328]}
                      >
                        <PricePerFeature
                          productName='SSD'
                          unity='Unidade'
                          unityPrice='1G'
                          hour='Valor por hora'
                          priceHour='R$ 0,00106850'
                          month='Valor por mês'
                          priceMonth='R$ 0,78'
                        />
                      </Div>
                    </Column>
                  </Row>
                  {loadMore && (
                    <>
                      <Row
                        justifyContent={'center'}
                        flexDirection={['column', 'column', 'column', 'row']}
                        alignItems={'center'}
                      >
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Banda Inbound'
                              unity='Unidade'
                              unityPrice='1G'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Banda Outbound'
                              unity='Unidade'
                              unityPrice='1G'
                              hour='Valor por hora'
                              priceHour={`10TB Free + R$ 0,04\n por GB excedente`}
                              month='Valor por mês'
                              priceMonth='- - -'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='IPv4'
                              unity='Unidade'
                              unityPrice='1'
                              hour='Valor por hora'
                              priceHour='R$ 0,03561644'
                              month='Valor por mês'
                              priceMonth='R$ 26,00'
                            />
                          </Div>
                        </Column>
                      </Row>
                      <Row
                        justifyContent={'center'}
                        flexDirection={['column', 'column', 'column', 'row']}
                        alignItems={'center'}
                      >
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='IPv6'
                              unity='Unidade'
                              unityPrice='1'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Backup / Image Storage (SATA)'
                              unity='Unidade'
                              unityPrice='1GB'
                              hour='Valor por hora'
                              priceHour='R$ 0,00035616'
                              month='Valor por mês'
                              priceMonth='R$ 0,26'
                            />
                          </Div>
                        </Column>
                        <Column
                          mb={[30]}
                        >
                          <Div
                            maxWidth={['100%', 328]}
                          >
                            <PricePerFeature
                              productName='Auto Scaling'
                              unity='Unidade'
                              unityPrice='On/Off'
                              hour='Valor por hora'
                              priceHour='Free'
                              month='Valor por mês'
                              priceMonth='Free'
                            />
                          </Div>
                        </Column>
                      </Row>
                    </>
                  )}
                </Div>
            }
          </Fragment>
          <Row
            justifyContent={'center'}
          >
            <Div
              alignItems={'center'}
            >
              <div
                css={btnLarge}
                onClick={() => setLoadMore(!loadMore)}
              >
                {loadMore ? 'OCULTAR ITENS' : 'EXIBIR ITENS'}
              </div>
            </Div>
          </Row>
        </Container>
      </section>
      <section css={styledRecommendedPlans}>
        <Container>
          <Row
            flexDirection={['column']}
            pt={50}
          >
            <Column
              width={[1]}
              mb={30}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <H5
                  style={{
                    color: 'rgba(0, 0, 0, 0.87)',
                    marginBottom: 24,
                    textAlign: 'center'
                  }}
                >
                  Planos Recomendados
                </H5>
                <MuiSelect
                  options={[
                    {label: 'DC Nordeste - Brasil', value: 0},
                    {label: 'DC Orlando - USA', value: 1},
                    {label: 'DC Colombia - COL', value: 2},
                    {label: 'DC México - MEX', value: 3}
                  ]}
                  label='DC Nordeste - Brasil'
                  onChange={(e) =>  setSelector(e.value)}
                  isClearable={false}
                  style={{
                    maxWidth: 328,
                    marginBottom: 20
                  }}
                />
                <Subtitle2
                  maxWidth={[200, '100%']}
                  style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    textAlign: 'center'
                  }}
                >
                  Brasil ou Estados Unidos? 
                  <span
                    onClick={() => setModalVisibility('moreInfo')(true)}
                    style={{
                      color: 'rgba(0, 0, 0, 0.87)',
                      marginLeft: 2,
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    }}
                  >
                    Entenda a diferença
                  </span>
                </Subtitle2>
              </div>
            </Column>            
          </Row>
          <Fragment>
            {selector === 0 ?
              <Div>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    order={[3, 3, 1, 1]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice=' 935,96 '
                        productDiscount='R$ 960,96'
                        // productSaveMoney='Economize 16%'
                        vCpuValue='8'
                        vCpu='vCPU'
                        ramValue='32GB'
                        ram='RAM'
                        ssdValue='300GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[1, 1, 2, 2]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='243,75'
                        productDiscount='R$ 259,74'
                        // productSaveMoney='Economize 10%'
                        vCpuValue='2'
                        vCpu='vCPU'
                        ramValue='8GB'
                        ram='RAM'
                        ssdValue='75GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[2, 2, 3, 3]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='468,48'
                        productDiscount='R$ 493,48'
                        // productSaveMoney='Economize 13%'
                        vCpuValue='4'
                        vCpu='vCPU'
                        ramValue='16GB'
                        ram='RAM'
                        ssdValue='150GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                </Row>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='1.870,92'
                        productDiscount='R$ 1.895,92'
                        // productSaveMoney='Economize 18%'
                        vCpuValue='16'
                        vCpu='vCPU'
                        ramValue='64GB'
                        ram='RAM'
                        ssdValue='600GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                </Row>
              </Div>
            : selector === 1 ?
              <Div>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    order={[3, 3, 1, 1]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='719,20'
                        productDiscount='R$ 739,20'
                        // productSaveMoney='Economize 16%'
                        vCpuValue='8'
                        vCpu='vCPU'
                        ramValue='32GB'
                        ram='RAM'
                        ssdValue='300GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[1, 1, 2, 2]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='179,80'
                        productDiscount='R$ 199,80'
                        // productSaveMoney='Economize 10%'
                        vCpuValue='2'
                        vCpu='vCPU'
                        ramValue='8GB'
                        ram='RAM'
                        ssdValue='75GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[2, 2, 3, 3]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='359,60'
                        productDiscount='R$ 379,60'
                        // productSaveMoney='Economize 13%'
                        vCpuValue='4'
                        vCpu='vCPU'
                        ramValue='16GB'
                        ram='RAM'
                        ssdValue='150GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                </Row>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='1.438,40'
                        productDiscount='R$ 1.458,40'
                        productSaveMoney='Economize 18%'
                        vCpuValue='16'
                        vCpu='vCPU'
                        ramValue='64GB'
                        ram='RAM'
                        ssdValue='600GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                </Row>
              </Div>
            : selector === 2 ?
              <Div>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    order={[3, 3, 1, 1]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice=' 935,96 '
                        productDiscount='R$ 960,96'
                        // productSaveMoney='Economize 16%'
                        vCpuValue='8'
                        vCpu='vCPU'
                        ramValue='32GB'
                        ram='RAM'
                        ssdValue='300GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[1, 1, 2, 2]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='243,75'
                        productDiscount='R$ 259,74'
                        // productSaveMoney='Economize 10%'
                        vCpuValue='2'
                        vCpu='vCPU'
                        ramValue='8GB'
                        ram='RAM'
                        ssdValue='75GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[2, 2, 3, 3]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='468,48'
                        productDiscount='R$ 493,48'
                        // productSaveMoney='Economize 13%'
                        vCpuValue='4'
                        vCpu='vCPU'
                        ramValue='16GB'
                        ram='RAM'
                        ssdValue='150GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                </Row>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='1.870,92'
                        productDiscount='R$ 1.895,92'
                        // productSaveMoney='Economize 18%'
                        vCpuValue='16'
                        vCpu='vCPU'
                        ramValue='64GB'
                        ram='RAM'
                        ssdValue='600GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                </Row>
              </Div>
              : 
              <Div>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    order={[3, 3, 1, 1]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='935,96'
                        productDiscount='R$ 960,96'
                        productSaveMoney='Economize 16%'
                        vCpuValue='8'
                        vCpu='vCPU'
                        ramValue='32GB'
                        ram='RAM'
                        ssdValue='300GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[1, 1, 2, 2]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='243,75'
                        productDiscount='R$ 259,74'
                        productSaveMoney='Economize 10%'
                        vCpuValue='2'
                        vCpu='vCPU'
                        ramValue='8GB'
                        ram='RAM'
                        ssdValue='75GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    order={[2, 2, 3, 3]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='468,48'
                        productDiscount='R$ 493,48'
                        productSaveMoney='Economize 13%'
                        vCpuValue='4'
                        vCpu='vCPU'
                        ramValue='16GB'
                        ram='RAM'
                        ssdValue='150GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                </Row>
                <Row
                  justifyContent={'center'}
                  flexDirection={['column', 'column', 'column', 'row']}
                  alignItems={'center'}
                >
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                  <Column
                    mb={[30]}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                    >
                      <RecommendedPlans
                        productPrice='1.870,92'
                        productDiscount='R$ 1.895,92'
                        productSaveMoney='Economize 18%'
                        vCpuValue='16'
                        vCpu='vCPU'
                        ramValue='64GB'
                        ram='RAM'
                        ssdValue='600GB'
                        ssd='SSD'
                        bandWidthValue='10TB'
                        bandWidth='Bandwidth'
                        Ipv4Value='1x'
                        Ipv4='IPv4'
                        productBtn='SELECIONAR'
                      />
                    </Div>
                  </Column>
                  <Column
                    mb={[30]}
                    display={['none', 'none', 'unset', 'unset']}
                  >
                    <Div
                      maxWidth={['100%', 328]}
                      style={{
                        background: '#f9fafa',
                        height: 543,
                        width: 328,
                        borderRadius: 12
                      }}
                    />
                  </Column>
                </Row>
              </Div>
            }
          </Fragment>
        </Container>
      </section>
      <section css={styledCloudConfig} style={{ backgroundColor: '#FFF' }}>
        <Container>
          <EnhancePriceForm
            setModalVisibility={setModalVisibility}
          />
        </Container>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Preços | Cloud Server HostDime' 
        description='Com a Cloud Server HostDime, você cria e implementa máquinas virtuais personalizadas, pagando pelo que usar e apenas quando usar.' 
      />
      <PageTemplate
        title='Preços Dedicados DC Outlet'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
