import React from 'react'
import { css } from '@emotion/core'

import { mq } from '../components/styles/Layout.styles'
import Div from '../components/Div'
import H3 from '../components/H3'
import Body2 from '../components/Body2'
import OutlineButton from '../components/OutlinedButton'
import ds from '../theme'

import { 
  currency
} from '../components/styles/DedicatedServersPrice.styles'

const RecommendedPlans = ({
  productDiscount,
  productPrice,
  productSaveMoney,
  vCpu,
  vCpuValue,
  ram,
  ramValue,
  ssd,
  ssdValue,
  bandWidth,
  bandWidthValue,
  Ipv4,
  Ipv4Value,
  productBtn,
}) => {
  return (
    <div
      style={{
        padding: '30px 16px 30px 16px',
        backgroundColor: '#ffffff',
        borderRadius: 12,
        transition: 'box-shadow .3s ease-out,transform .3s ease-out,opacity .2s ease-out',
        transitionDelay: '.1s'
      }}
      css={
        css(
          mq({
            '&:hover': {
              transform: 'translate(0, -4px)',
            },
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='column'
        alignItems='center'
      >
        <H3
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            letterSpacing: 0.25,
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 296,
            fontWeight: 500
          }}
        >
          <span css={currency}>R$ </span>
          {productPrice}
          <span css={currency}> /Mês</span>
        </H3>
        <Div
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Div
            style={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: 1.5,
              letterSpacing: 0.12,
              textAlign: 'center',
              color: '#878694',
              // width: 90,
              textDecoration: 'line-through'
            }}
          >
            {productDiscount}
          </Div>
          {false && <Div
            style={{
              backgroundColor: '#fff5ef',
              borderRadius: 20,
              padding: '8px 18px',
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1.7,
              letterSpacing: 0.1,
              textAlign: 'center',
              color: '#ff5800',
              width: 150,
              justifyContent: 'center'
            }}
          >
            {productSaveMoney}
          </Div>}
        </Div>
        <Div 
          style={{
            width: 50,
            height: 1,
            borderBottom: 'solid 4px #f3f5f5',
            margin: '40px 0 30px 0'
          }}
        />
        <Div
          flexDirection={'row'}
          alignItems='center'
          style={{
            width: 202,
            height: 30,
            marginBottom: 30
          }}
        >
          <Body2 
            style={{
              fontSize: 28, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginRight: 8,
            }}>
            {vCpuValue}
          </Body2>
          <Body2 
            style={{
              fontSize: 18, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
            {vCpu}
          </Body2>
        </Div>
        <Div
          flexDirection={'row'}
          alignItems='center'
          style={{
            width: 202,
            height: 30,
            marginBottom: 30
          }}
        >
          <Body2 
            style={{
              fontSize: 28, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginRight: 8,
            }}>
            {ramValue}
          </Body2>
          <Body2 
            style={{
              fontSize: 18, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
            {ram}
          </Body2>
        </Div>
        <Div
          flexDirection={'row'}
          alignItems='center'
          style={{
            width: 202,
            height: 30,
            marginBottom: 30
          }}
        >
          <Body2 
            style={{
              fontSize: 28, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginRight: 8,
            }}>
            {ssdValue}
          </Body2>
          <Body2 
            style={{
              fontSize: 18, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
            {ssd}
          </Body2>
        </Div>
        <Div
          flexDirection={'row'}
          alignItems='center'
          style={{
            width: 202,
            height: 30,
            marginBottom: 30
          }}
        >
          <Body2 
            style={{
              fontSize: 28, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginRight: 8,
            }}>
            {bandWidthValue}
          </Body2>
          <Body2 
            style={{
              fontSize: 18, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
            {bandWidth}
          </Body2>
        </Div>
        <Div
          flexDirection={'row'}
          alignItems='center'
          style={{
            width: 202,
            height: 30,
            marginBottom: 40
          }}
        >
          <Body2 
            style={{
              fontSize: 28, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 600,
              color: 'rgba(0, 0, 0, 0.87)',
              marginRight: 8,
            }}>
            {Ipv4Value}
          </Body2>
          <Body2 
            style={{
              fontSize: 18, 
              textAlign: 'left',
              lineHeight: 2.5,
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
            {Ipv4}
          </Body2>
        </Div>
        <OutlineButton
          type='btnOutlinedSmall'
          Link='/contatos'
        >
          {productBtn}
        </OutlineButton>
      </Div>
    </div>
  )
}

export default RecommendedPlans
